import { useEffect, useState } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import HubDash from 'src/components/HubDash/HubDash'
import HubDashCell from 'src/components/HubDash/HubDashCell'
import SessionExpiredModal from 'src/components/SessionExpiredModal/SessionExpiredModal'
import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'
import { useBaserowTokenStorage } from 'src/lib/hooks/useBaserowTokenStorage'

const HubDashPage = () => {
  const [hubDashEnabled] = useLiveFeature(FLAG_FEATURES.HUBDASH)
  const { isValid } = useBaserowTokenStorage()
  const [showRefreshModal, setShowRefreshModal] = useState(false)

  useEffect(() => {
    const checkInterval = setInterval(() => {
      if (!isValid) {
        setShowRefreshModal(true)
      }
    }, 60000)

    return () => clearInterval(checkInterval)
  }, [isValid])

  if (hubDashEnabled) {
    return (
      <>
        <Metadata title="HubDash" description="HubDash" />

        {isValid ? <HubDash /> : <HubDashCell />}

        <SessionExpiredModal
          isOpen={showRefreshModal}
          title="Session Expired"
          description="Click the button to reload The Hub."
          buttonTestId="sidebar-reload-page-btn"
        />
      </>
    )
  }
}

export default HubDashPage
